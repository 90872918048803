import * as React from "react"
import Helmet from "react-helmet"
import {Link} from "gatsby"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckIcon from '@mui/icons-material/Check';
import _ from 'lodash';
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ResourceLink from "../../components/resourcelink"
import {useEffect, useRef, useState} from "react";
import {Alert, CircularProgress} from "@mui/material";

const FORM_ENDPOINT = ""

/*

Medical Information Request [directs to: medinfo@arenapharm.com]
	> Connect with a Medical Science Liaison (MSL) [directs to: MSLTeam@arenapharm.com]
	> Clinical Trials [directs to: medinfo@arenapharm.com]"

*/

const initialContactFormFieldValidationErrors = {
  topicInput: null,
  nameInput: null,
  emailInput: null,
  cityInput: null,
  stateInput: null,
  medicalCenterInput: null,
  messageInput: null,
}


const Resources = () => {

  const topicInputRef = useRef()
  const nameInputRef = useRef()
  const emailInputRef = useRef()
  const cityInputRef = useRef()
  const stateInputRef = useRef()
  const medicalCenterInputRef = useRef()
  const messageInputRef = useRef()

  const [contactFormErrorMessages, setContactFormErrorMessages] = useState([]);
  const [contactFormFieldValidationErrors, setContactFormFieldValidationErrors] = useState(initialContactFormFieldValidationErrors);
  const [contactFormSuccess, setContactFormSuccess] = useState(false);
  const [contactFormIsLoading, setContactFormIsLoading] = useState(false);

  const onInputChange = (field) => {
    const tmpContactFormFieldValidationErrors = contactFormFieldValidationErrors
    tmpContactFormFieldValidationErrors[field] = null
    setContactFormFieldValidationErrors({...tmpContactFormFieldValidationErrors})
  }

  const postContactForm = () => {

    setContactFormSuccess(false)
    setContactFormErrorMessages([])
    setContactFormIsLoading(true)

    new Promise((resolve, reject) => {

      const tmpContactFormErrorMessages = []
      const tmpContactFormFieldValidationErrors = initialContactFormFieldValidationErrors

      if (topicInputRef.current.value.length <= 0) {
        tmpContactFormFieldValidationErrors.topicInput = true
        tmpContactFormErrorMessages.push('Topic field is required!')
      }

      if (nameInputRef.current.value.length <= 0) {
        tmpContactFormFieldValidationErrors.nameInput = true
        tmpContactFormErrorMessages.push('Name field is required!')
      }

      if (emailInputRef.current.value.length <= 0) {
        tmpContactFormFieldValidationErrors.emailInput = true
        tmpContactFormErrorMessages.push('Email field is required!')
      }

      if (messageInputRef.current.value.length <= 0) {
        tmpContactFormFieldValidationErrors.messageInput = true
        tmpContactFormErrorMessages.push('Message field is required!')
      }

      setContactFormErrorMessages(tmpContactFormErrorMessages)
      setContactFormFieldValidationErrors(tmpContactFormFieldValidationErrors)

      if (tmpContactFormErrorMessages.length > 0) {
        return reject(tmpContactFormErrorMessages)
      }

      
      fetch('/forms/submit.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({
          topic: topicInputRef.current.value,
          name: nameInputRef.current.value,
          email: emailInputRef.current.value,
          city: cityInputRef.current.value,
          state: stateInputRef.current.value,
          medical_center: medicalCenterInputRef.current.value,
          message: messageInputRef.current.value,
        })
      }).then((res) => {

        if (res.ok) { //Means response status code between 200-299
          const data = res.json();

          if (data) {
            if (data.error) {
              //Error key returned in json
              return reject(data.error)
            } else {
              //Success
              return resolve()
            }
          } else {
            //JSON didnt read
            return reject("A problem occurred. PLease try again later.")
          }
        } else {
          //Any other status codes
        }
      }).then(() => setContactFormSuccess(true))
        .catch((err) => {
          return reject(err)
        })

    }).catch((err) => {
      if (!_.isArray(err))
        err = [err]

      setContactFormErrorMessages(err)
    }).finally(() => setContactFormIsLoading(false))

  }
  
  function formSuccess(boolean)
	{
		if(boolean === true)
			{return "success"}
		else
		return ""
	}

  return (
    <Layout>
      <Helmet>
        <script src={'/js/main.js'} type="text/javascript"/>
        <script src={'/js/current-research.js'} type="text/javascript"/>
      </Helmet>
      <Seo title="Publications Archive"/>
      <section className="spacer"/>
      <section className="wrap"><img src={'/images/resources-graphic-image.svg'} alt="Resources" id="resources-img"/><br/><h2 className="perfectSpace">How to reach the right resources within Arena and connect with the gastroenterology community</h2><br/>
        <header className="orange rule" id="contact"><h1>CONTACT <span>ARENA</span></h1></header>
        <div>
          <Box mb={3} p={(contactFormIsLoading) ? 3 : 0}>
            {contactFormErrorMessages.length > 0 &&
              <Alert severity="error">{contactFormErrorMessages.map((err, index) =>
                <div key={`alert-${index}`}>{err}</div>)} </Alert>}

            {contactFormSuccess === true && <Alert severity="success">Form sent successfully!</Alert>}
          </Box>
{formSuccess}
          <div id="contactForm" className={formSuccess(contactFormSuccess === true)} >

            <div id="contactContainer">
              <select id="topic" name="topic" ref={topicInputRef} disabled={contactFormSuccess} onChange={() => onInputChange('topicInput')}
                      className={contactFormFieldValidationErrors.topicInput ? "field-error" : ''}>
                <option value="" selected>Select a Topic*</option>
                <option>Medical Information Request</option>
                <option>Connect with a Medical Science Liaison (MSL)</option>
                <option>Clinical Trials</option>
              </select>

              <div className="formHolder">
                <input placeholder="Name*" type="text" id="name" name="name" ref={nameInputRef}
                       disabled={contactFormSuccess}
                       className={contactFormFieldValidationErrors.nameInput ? "field-error" : ''}
                       onChange={() => onInputChange('nameInput')}
                />
                <input placeholder="Email*" type="text" id="email" name="email" ref={emailInputRef}
                       disabled={contactFormSuccess}
                       className={contactFormFieldValidationErrors.emailInput ? "field-error" : ''}
                       onChange={() => onInputChange('emailInput')}
                />
              </div>
              <div className="formHolder">
                <input placeholder="City" id="city" name="state" ref={cityInputRef} disabled={contactFormSuccess}
                       className={contactFormFieldValidationErrors.cityInput ? "field-error" : ''}
                       onChange={() => onInputChange('cityInput')}
                />
                <select id="state" name="state" ref={stateInputRef} disabled={contactFormSuccess}
                        className={contactFormFieldValidationErrors.stateInput ? "field-error" : ''}
                        onChange={() => onInputChange('stateInput')}
                >
                  <option value="AL">AL</option>


  <option value="AK">AK</option>
<option value="AS">American Samoa</option>
  <option value="AZ">AZ</option>
  <option value="AR">AR</option>
  <option value="CA">CA</option>
  <option value="CO">CO</option>
  <option value="CT">CT</option>
<option value="DC">DC</option>
  <option value="DE">DE</option>
  <option value="FL">FL</option>
  <option value="GA">GA</option>
<option value="GU">Guam</option>

  <option value="HI">HI</option>
  <option value="ID">ID</option>
  <option value="IL">IL</option>
  <option value="IN">IN</option>
  <option value="IA">IA</option>
  <option value="KS">KS</option>
  <option value="KY">KY</option>
  <option value="LA">LA</option>
  <option value="ME">ME</option>
  <option value="MD">MD</option><option value="MP">Northern Mariana Islands</option>

  <option value="MA">MA</option>
  <option value="MI">MI</option>
  <option value="MN">MN</option>
  <option value="MS">MS</option>
  <option value="MO">MO</option>
  <option value="MT">MT</option>
  <option value="NE">NE</option>
  <option value="NV">NV</option>
  <option value="NH">NH</option>
  <option value="NJ">NJ</option>
  <option value="NM">NM</option>
  <option value="NY">NY</option>
  <option value="NC">NC</option>
  <option value="ND">ND</option>
  <option value="OH">OH</option>
  <option value="OK">OK</option>
  <option value="OR">OR</option>
  <option value="PA">PA</option><option value="PR">Puerto Rico</option>

  <option value="RI">RI</option>
  <option value="SC">SC</option>
  <option value="SD">SD</option>
  <option value="TN">TN</option>
  <option value="TX">TX</option><option value="UM">United States Minor Outlying Islands</option>

  <option value="UT">UT</option>
  <option value="VT">VT</option>
  <option value="VA">VA</option><option value="VI">Virgin Islands</option>
  <option value="WA">WA</option>
  <option value="WV">WV</option>
  <option value="WI">WI</option>
  <option value="WY">WY</option>
                </select>
                <input placeholder="Medical Center" type="text" id="medicalCenter" name="medicalCenter"
                       ref={medicalCenterInputRef} disabled={contactFormSuccess}
                       className={contactFormFieldValidationErrors.medicalCenterInput ? "field-error" : ''}
                       onChange={() => onInputChange('medicalCenterInput')}
                />
              </div>
              <div className="formHolder">
                <textarea placeholder="Type your message here*" id="message" name="message" ref={messageInputRef}
                          disabled={contactFormSuccess}
                          className={contactFormFieldValidationErrors.messageInput ? "field-error" : ''}
                          onChange={() => onInputChange('messageInput')}
                />
              </div>
              <div style={{marginTop: 6, paddingTop: 20}}>
                <text style={{fontSize: 12}}>*Required field.</text>
              </div>
              <div style={{paddingBottom: 20}}>
                <button
                  style={{
                    border: '1px solid #A2A2A2',
                    backgroundColor: "#FFFFFF",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: '5px 25px',
                    height: '40px',
                    width: '150px'
                  }}
                  type="button"
                  onClick={postContactForm}
                  disabled={contactFormIsLoading || contactFormSuccess}
                >
                  {contactFormSuccess ? <>
                      <text style={{fontSize: 16, color: "#364d93"}}>Sent</text>
                      <CheckIcon color="success" fontSize={"small"}/></> :

                    contactFormIsLoading ? <CircularProgress size={20} color="success"/> : <>
                      <text style={{fontSize: 16, color: "#364d93"}}>Submit</text>
                      <ArrowRightIcon color="success" size={15}/></>}

                </button>
              </div>

            </div>
<img src={'/images/thank-you-message.svg'} id="thankYou" />
          </div>
        </div>
      </section>
      <section className="spacer"/>
      <section className="spacer"/>
      <section className="wrap">
        <h1 className="special" id="connect">CONNECT WITH THE</h1>
        <header className="orange rule"><h1><span>GASTROENTEROLOGY COMMUNITY</span></h1></header>
       
        <div className="full inline">
          <div className="tabHolder col2">
            <div className="tab selected"><a href="#tab0">Professional Societies</a></div>
          </div>
        </div>
        <div className="full inline">
          <section className="tabContent col2">
            <div className="content rows selected special">

              <ResourceLink
                title="American College of Gastroenterology (ACG)"
                url="https://gi.org/"
              />

              <ResourceLink
                title="American Gastroenterological Association (AGA)"
                url="https://gastro.org/"
              />

              <ResourceLink
                title="American Society for Gastrointestinal Endoscopy (ASGE)"
                url="https://www.asge.org/"
              />

              <ResourceLink
                title="Crohn's & Colitis Foundation (CCF)"
                url="https://www.crohnscolitisfoundation.org/ "
              />

              <ResourceLink
                title="European Crohn’s and Colitis Organisation (ECCO)"
                url="https://www.ecco-ibd.eu/"
              />

              <ResourceLink
                title="Society of American Gastrointestinal and Endoscopic Surgeons (SAGES)"
                url="https://www.sages.org/"
              />

              <ResourceLink
                title="Society of Gastroenterology Nurses and Associates (SGNA)"
                url="https://www.sgna.org/"
              />

              <ResourceLink
                title="United European Gastroenterology (UEG)"
                url="https://ueg.eu/"
              />

              <ResourceLink
                title="World Endoscopy Organization (WEO)"
                url="https://www.worldendo.org/"
              />

              <ResourceLink
                title="World Gastroenterology Organisation (WGO)"
                url="https://www.worldgastroenterology.org/"
              />


            </div>


          </section>
        </div>
      </section>
      <section className="spacer"/>
      <footer className="definitions">ECCO=European Crohn’s and Colitis Organisation; IBD=inflammatory bowel disease;
        MOA=mechanism of action.
      </footer>
    </Layout>
  )
}

export default Resources