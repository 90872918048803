import * as React from "react"

const ResourceLink = ( {title, url} ) => {
  return (
	 <>
     <a href={url} target="_blank" rel="noreferrer">
      <h5>{title}</h5>
	  <img src={"/images/visit-site.svg"} alt="Visit Site"  width="122" height="35" />
	  </a>
	  </>
  )
}


export default ResourceLink
